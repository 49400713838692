
module.exports = (function(){
  var d = _eai_d;
  var r = _eai_r;
  window.emberAutoImportDynamic = function(specifier) {
    if (arguments.length === 1) {
      return r('_eai_dyn_' + specifier);
    } else {
      return r('_eai_dynt_' + specifier)(Array.prototype.slice.call(arguments, 1))
    }
  };
  window.emberAutoImportSync = function(specifier) {
    return r('_eai_sync_' + specifier)(Array.prototype.slice.call(arguments, 1))
  };
  function esc(m) {
    return m && m.__esModule ? m : Object.assign({ default: m }, m);
  }
    d('@apollo/client/core', EAI_DISCOVERED_EXTERNALS('@apollo/client/core'), function() { return esc(require('@apollo/client/core')); });
    d('@apollo/client/link/context', EAI_DISCOVERED_EXTERNALS('@apollo/client/link/context'), function() { return esc(require('@apollo/client/link/context')); });
    d('@bugsnag/js', EAI_DISCOVERED_EXTERNALS('@bugsnag/js'), function() { return esc(require('@bugsnag/js')); });
    d('algoliasearch', EAI_DISCOVERED_EXTERNALS('algoliasearch'), function() { return esc(require('algoliasearch')); });
    d('ember-modifier', EAI_DISCOVERED_EXTERNALS('ember-modifier'), function() { return esc(require('ember-modifier')); });
    d('fast-deep-equal', EAI_DISCOVERED_EXTERNALS('fast-deep-equal'), function() { return esc(require('fast-deep-equal')); });
    d('focus-trap', EAI_DISCOVERED_EXTERNALS('focus-trap'), function() { return esc(require('focus-trap')); });
    d('hammerjs', EAI_DISCOVERED_EXTERNALS('hammerjs'), function() { return esc(require('hammerjs')); });
    d('highlight-within-textarea', EAI_DISCOVERED_EXTERNALS('highlight-within-textarea'), function() { return esc(require('highlight-within-textarea')); });
    d('intersection-observer-admin', EAI_DISCOVERED_EXTERNALS('intersection-observer-admin'), function() { return esc(require('intersection-observer-admin')); });
    d('js-video-url-parser', EAI_DISCOVERED_EXTERNALS('js-video-url-parser'), function() { return esc(require('js-video-url-parser')); });
    d('lodash', EAI_DISCOVERED_EXTERNALS('lodash'), function() { return esc(require('lodash')); });
    d('lodash-es', EAI_DISCOVERED_EXTERNALS('lodash-es'), function() { return esc(require('lodash-es')); });
    d('miragejs', EAI_DISCOVERED_EXTERNALS('miragejs'), function() { return esc(require('miragejs')); });
    d('moment', EAI_DISCOVERED_EXTERNALS('moment'), function() { return esc(require('moment')); });
    d('moment-duration-format', EAI_DISCOVERED_EXTERNALS('moment-duration-format'), function() { return esc(require('moment-duration-format')); });
    d('moment-timezone', EAI_DISCOVERED_EXTERNALS('moment-timezone'), function() { return esc(require('moment-timezone')); });
    d('raf-pool', EAI_DISCOVERED_EXTERNALS('raf-pool'), function() { return esc(require('raf-pool')); });
    d('react', EAI_DISCOVERED_EXTERNALS('react'), function() { return esc(require('react')); });
    d('react-dom', EAI_DISCOVERED_EXTERNALS('react-dom'), function() { return esc(require('react-dom')); });
    d('react-dom/client', EAI_DISCOVERED_EXTERNALS('react-dom/client'), function() { return esc(require('react-dom/client')); });
    d('screenfull', EAI_DISCOVERED_EXTERNALS('screenfull'), function() { return esc(require('screenfull')); });
    d('sticky-sidebar-v2', EAI_DISCOVERED_EXTERNALS('sticky-sidebar-v2'), function() { return esc(require('sticky-sidebar-v2')); });
    d('swiper', EAI_DISCOVERED_EXTERNALS('swiper'), function() { return esc(require('swiper')); });
    d('swiper/bundle', EAI_DISCOVERED_EXTERNALS('swiper/bundle'), function() { return esc(require('swiper/bundle')); });
    d('textarea-caret', EAI_DISCOVERED_EXTERNALS('textarea-caret'), function() { return esc(require('textarea-caret')); });
    d('ua-parser-js', EAI_DISCOVERED_EXTERNALS('ua-parser-js'), function() { return esc(require('ua-parser-js')); });
    d('validated-changeset', EAI_DISCOVERED_EXTERNALS('validated-changeset'), function() { return esc(require('validated-changeset')); });
    d('_eai_dyn_filestack-js', [], function() { return import('filestack-js'); });
})();
